import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Tiffany Ray | Software Developer',
  lang: 'en',
  description: 'Hire Software Developer. ', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Tiffany Ray',
  subtitle: "I'm a software developer.",
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'tiffanyRayPortrait.jpg',
  paragraphOne:
    'I am a design-oriented web developer. Curiosity about tech & design lead me to web development. After months of learning and freelancing, I got a job as a Software Developer. In this position, I grew to become a full-stack developer having my hands in every piece of the project.',
  paragraphTwo:
    'I am a driven, well-organized employee with high attention to detail. I found that my passion is connecting with clients to bring their projects to life. I thrive juggling different projects and roles. I prefer to keep learning, challenging myself, and do interesting things that matter.',
  paragraphThree:
    'When not online, I enjoy being outdoors backpacking, practicing yoga, or snowboarding.',
  resume: '',
};

// SERVICES DATA
export const serviceData = [
  // I don't like doing this but I know how. I've got plenty of work.
  // {
  //   id: nanoid(),
  //   img: '',
  //   title: 'WordPress Development',
  //   description: 'WordPress is the world’s most popular content management system. I have experience in creating custom themes and plugins to editing existing websites, themes, and migrations.',
  //   skills: ['Theme Development', 'Plugin Development', 'SEO', 'eCommerce', 'Maintenance & Updates']
  // },
  {
    id: nanoid(),
    img: '',
    title: 'Frontend Development',
    description:
      'Frontend Development is building the visual components that make a website. Using HTML, CSS, JavaScript to build small to medium size web apps with React. I love bringing life to the web with animations, and coding interactive layouts. This could include a CMS, API’s, plugins, or other integrations.',
    skills: [
      'Javascript (React)',
      'NPM',
      'NodeJs',
      'jQuery',
      'Gatsbyjs',
      'Animation',
      'Responsive CSS',
      'SASS',
      'Bootstrap',
      'JSON',
      'SEO',
    ],
  },
  {
    id: nanoid(),
    img: '',
    title: 'Backend Developer',
    description:
      'Backend Development is the functionality and logic the powers the frontend. I have experience with .Net Core, a development framework for building cross-platform applications.',
    skills: ['C#', 'SQL Server', 'SQL Lite', 'Entity Framework', 'LINQ', 'REST'],
  },
  {
    id: nanoid(),
    img: '',
    title: 'Deployment',
    description:
      'I have experience with Microsoft’s Azure and Amazon AWS Cloud Services. I have deployed many different resource and application infrastructures.',
    skills: ['Azure', 'AWS', 'Firebase', 'Netlify'],
  },
];

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'Indecision-app.png',
    title: 'Indecision',
    info: 'A React app for for indecisive people.',
    info2: '',
    url: 'https://tiffanyray.github.io/indecision/',
    repo: 'https://github.com/tiffanyray/indecision',
  },
  {
    id: nanoid(),
    img: 'Markdown-app.png',
    title: 'Markdown Generator',
    info: '',
    info2: '',
    url: 'https://tiffanyray.github.io/markdown/',
    repo: 'https://github.com/tiffanyray/markdown',
  },
  {
    id: nanoid(),
    img: 'Quote-app.jpeg',
    title: 'Quote Generator',
    info: '',
    info2: '',
    url: 'https://tiffanyray.github.io/quoteGenerator/',
    repo: 'https://github.com/tiffanyray/quoteGenerator',
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Have a project, idea or problem you would like to discuss?',
  btn: "Let's chat",
  email: 'tiffany@tiffanyray.dev',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/tiffanylray/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tiffanyray',
    },
  ],
};
